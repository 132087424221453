import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import {
    CmsFormsService,
    FormTemplate,
    FormTemplatesResponse,
    parseForm
} from '../forms.service';
import { CmsAppsService, App } from '../../apps/shared/apps.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { NameValidationPattern } from '../../shared/common.service';
import { sortBy } from 'lodash';
import { validateJson } from '../../shared/json-editor/json-validator.directive';
import {finalize} from "rxjs/operators";

@Component({
    selector: 'cms-new-form',
    templateUrl: 'new-form.component.html',
    providers: [CmsFormsService]
})

export class CmsNewFormComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    apps: Array<App>;
    formTemplates: Array<FormTemplate>;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;
    submitDisabled = false;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private appService: CmsAppsService,
        private translateService: CmsTranslateService,
        private formService: CmsFormsService
    ) {
        this.form = this.fb.group({
            application: ['', [Validators.required]],
            formTemplate: [null, [Validators.required]],
            name: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            data: [{}, [Validators.required, validateJson]],
            active: [true, []]
        });

        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };

        this.appService.getAllApps().subscribe(
            (data) => {
                if (data.apps && data.apps.length) {
                    this.apps = sortBy(data.apps, 'title');
                }
            }
        );
    }

    ngOnInit() {
        this.setApplicationChangeListener();
        this.setFormTemplateChangeListener();
    }

    onSubmit(form: FormGroup) {
        this.submitDisabled = true;
        this.formService.createForm({
            appId: form.value.application.id,
            appFormTemplateId: form.value.formTemplate.id,
            name: form.value.name,
            title: form.value.title,
            data: form.value.data,
            active: form.value.active
        }).pipe(
            finalize(() => {
                this.submitDisabled = true;
            })
        ).subscribe(
            res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('forms_create_form_success')
                });
                this.closePopup().then( () => {
                    this.notifyService.notify('formNew');
                });
            },
            error => {
                this.notificationsService.showError(error);
            }
        );
    }

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'preserve'}
        );
    }

    setApplicationChangeListener() {
        this.form.controls['application'].valueChanges.subscribe((data) => {
            this.getFormTemplates(data);
        });
    }

    getFormTemplates(appData) {
        const params: Object = {appTemplateId: appData.appTemplate};

        this.formService.getFormTemplates(params).subscribe(
            (data: FormTemplatesResponse) => {
                if (data && data.formTemplates) {
                    // Reset form
                    this.form.patchValue({
                        formTemplate: null,
                        data: {}
                    });
                    this.formTemplates = data.formTemplates;
                }
            },
            (error) =>  {
                this.notificationsService.showError(error);
            }
        );
    }

    setFormTemplateChangeListener() {
        this.form.controls['formTemplate'].valueChanges.subscribe((data) => {
            if (data) {
                this.form.patchValue({
                    data: data.data,
                });
            }
        });
    }
}
