import { forkJoin } from 'rxjs';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsLayout, CmsPagesService, CmsPageTemplate, FIELDS_OVERRIDE_WHITELIST } from '../shared/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsAppsService } from '../../apps/shared/apps.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { filter, cloneDeep, isEqual } from 'lodash';
import { validateJson } from '../../shared/json-editor/json-validator.directive';
import { validateCheckboxRequired } from '../../shared/validators.service';
import { NameValidationPattern } from '../../shared/common.service';
import { finalize } from "rxjs/operators";
var CmsEditPageComponent = /** @class */ (function () {
    function CmsEditPageComponent(fb, activeRoute, router, pageService, translateService, appService, notifyService, notificationsService) {
        this.fb = fb;
        this.activeRoute = activeRoute;
        this.router = router;
        this.pageService = pageService;
        this.translateService = translateService;
        this.appService = appService;
        this.notifyService = notifyService;
        this.notificationsService = notificationsService;
        this.classes = 'popup-overlay';
        this.layoutsData = [];
        this.apps = [];
        this.linkedPageTemplate = null;
        this.customDataFieldChanged = false;
        this.layoutFieldChanged = false;
        this.pageTemplates = [];
        this.submitDisabled = false;
        this.titleValidators = [
            Validators.required, Validators.maxLength(80)
        ];
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.subtitleValidationInterpolationParams = {
            field: this.translateService.translate('common_subtitle').toLowerCase(),
            maximum: '120'
        };
        this.keywordsValidationInterpolationParams = {
            field: this.translateService.translate('common_keywords').toLowerCase(),
            maximum: '120'
        };
    }
    CmsEditPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            name: ['', [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.pattern(NameValidationPattern.pattern)
                ]
            ],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            subtitle: ['', [Validators.maxLength(120)]],
            app: ['', [Validators.required]],
            linkedPageTemplate: [''],
            keywords: ['', [Validators.maxLength(120)]],
            customData: [{}, [validateJson]],
            active: [true, []]
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.pageService.getPage(params['id']),
                _this.pageService.getLayouts(),
                _this.appService.getAllApps(),
                _this.pageService.getPageTemplates({ pageSize: 100000 })
            ]).subscribe(function (_a) {
                var pageData = _a[0], layoutsData = _a[1], appsData = _a[2], pageTemplates = _a[3];
                _this.linkedPageTemplate = pageData.pages.linkedPageTemplate;
                _this.pageTemplates = pageTemplates.pageTemplates;
                _this.layoutsData = layoutsData.layouts;
                _this.form.addControl('layout', _this.buildLayouts((pageData.pages.layouts.length && pageData.pages.layouts) || (_this.linkedPageTemplate
                    && _this.linkedPageTemplate.layouts)));
                _this.form.controls.layout.setValidators([validateCheckboxRequired]);
                _this.apps = appsData.apps;
                _this.form.patchValue(pageData.pages);
                _this.customDataFieldChanged = !isEqual(_this.form.get('customData').value, _this.linkedPageTemplate && _this.linkedPageTemplate.customData);
                _this.form.get('customData').valueChanges.subscribe(function (data) {
                    _this.customDataFieldChanged = !isEqual(data, _this.linkedPageTemplate.customData);
                });
                _this.layoutFieldChanged = !isEqual(_this.getPageTemplateSelectedLayouts(_this.linkedPageTemplate && _this.linkedPageTemplate.layouts), _this.form.get('layout').value);
                _this.form.get('layout').valueChanges.subscribe(function (value) {
                    _this.layoutFieldChanged = !isEqual(_this.getPageTemplateSelectedLayouts(_this.linkedPageTemplate.layouts), value);
                });
            });
        });
    };
    CmsEditPageComponent.prototype.onSubmit = function (form) {
        var _this = this;
        var updatedPage = cloneDeep(form.value), selectedLayouts = form.value.layout.map(function (val, index) {
            return {
                id: _this.layoutsData[index].id,
                selected: val
            };
        }).filter(function (val) {
            return val.selected;
        }), selectedPageTemplate = this.form.controls.linkedPageTemplate.value;
        this.submitDisabled = true;
        if (selectedPageTemplate) {
            updatedPage.layouts = selectedLayouts;
        }
        var layoutSelected = updatedPage.layout.find(function (layout) {
            return !!layout;
        });
        updatedPage.layouts = selectedLayouts;
        updatedPage.linkedPageTemplate = !layoutSelected ?
            null : (form.value.linkedPageTemplate && (form.value.linkedPageTemplate.id || form.value.linkedPageTemplate));
        updatedPage.app = form.value.app && (form.value.app.id || form.value.app);
        // needed only local to build checkboxes
        delete updatedPage.layout;
        this.pageService.updatePage(updatedPage, this.activeRoute.snapshot.params['id']).pipe(finalize(function () {
            _this.submitDisabled = true;
        })).subscribe(function (response) {
            _this.notifyService.notify({
                type: 'pages',
                data: response.pages
            });
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('pages_page_edited')
            });
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsEditPageComponent.prototype.buildLayouts = function (activeLayouts) {
        var _this = this;
        var arr = this.layoutsData.map(function (layout) {
            var found = filter(activeLayouts, function (el, index) {
                return el.id === layout.id;
            });
            return _this.fb.control(found.length);
        });
        return this.fb.array(arr);
    };
    Object.defineProperty(CmsEditPageComponent.prototype, "layouts", {
        get: function () {
            return this.form.get('layout');
        },
        enumerable: true,
        configurable: true
    });
    ;
    CmsEditPageComponent.prototype.selectPageTemplate = function (e) {
        var _this = this;
        // on select page we must override some fields from chosen page template
        if (!e) {
            return;
        }
        this.pageService.getPageTemplate(e.id).subscribe(function (res) {
            var titleControl = _this.form.controls.title, pageTemplate = res.pageTemplate;
            _this.linkedPageTemplate = res.pageTemplate;
            var oldLinkedPageTemplate = cloneDeep(_this.linkedPageTemplate);
            _this.layoutFieldChanged = !isEqual(_this.getPageTemplateSelectedLayouts(_this.linkedPageTemplate.layouts), _this.form.get('layout').value);
            _this.customDataFieldChanged = !isEqual(_this.form.get('customData').value, _this.linkedPageTemplate.customData);
            var _loop_1 = function (key) {
                if (_this.form.controls.hasOwnProperty(key)) {
                    // if formControl is on list of override fields we will try to override value
                    var foundKey = FIELDS_OVERRIDE_WHITELIST.find(function (field) {
                        return field.controlKey === key;
                    });
                    if (foundKey) {
                        var control = _this.form.controls[foundKey.controlKey], oldValue = oldLinkedPageTemplate.hasOwnProperty([foundKey['serverKey'] || foundKey.controlKey]) ?
                            oldLinkedPageTemplate[foundKey['serverKey'] || foundKey.controlKey] : false;
                        // if property in page template exist and current page controls
                        // is not updated by user
                        if (oldValue !== false && control.pristine) {
                            // now compare current value with old linked page value
                            // and if they are some override it with new value
                            if (key === 'layout' &&
                                isEqual(_this.getPageTemplateSelectedLayouts(oldValue), control.value)) {
                                // now selected layouts extend from chosen page template
                                control.patchValue(_this.getPageTemplateSelectedLayouts(pageTemplate.layouts));
                            }
                            else if (key === 'customData'
                                && (control.value === '' || isEqual(oldValue, control.value))) {
                                control.patchValue(pageTemplate[key]);
                            }
                            else if (oldValue === control.value || control.value === '') {
                                control.patchValue(pageTemplate[key]);
                            }
                        }
                    }
                }
            };
            for (var key in _this.form.controls) {
                _loop_1(key);
            }
            // if page template is chosen title is not required field,
            // only set max length validator
            titleControl.setValidators([_this.titleValidators[1]]);
            titleControl.updateValueAndValidity();
        });
    };
    CmsEditPageComponent.prototype.getPageTemplateSelectedLayouts = function (pageTemplateLayouts) {
        var _this = this;
        var newState = [];
        // now select layouts extend from chosen page template
        this.form.controls.layout.value.forEach(function (val, index) {
            var currentLayout = _this.layoutsData[index];
            var found = filter(pageTemplateLayouts, function (el) {
                return el.id === currentLayout.id;
            });
            return found.length ? newState.push(1) : newState.push(0);
        });
        return newState;
    };
    CmsEditPageComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    return CmsEditPageComponent;
}());
export { CmsEditPageComponent };
