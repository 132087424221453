import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsPagesService, FIELDS_OVERRIDE_WHITELIST } from '../shared/pages.service';
import { CmsAppsService } from '../../apps/shared/apps.service';
import { filter, cloneDeep, isEqual } from 'lodash';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { validateJson } from '../../shared/json-editor/json-validator.directive';
import { validateCheckboxRequired } from '../../shared/validators.service';
import { NameValidationPattern } from '../../shared/common.service';
import { finalize } from "rxjs/operators";
var CmsNewPageComponent = /** @class */ (function () {
    function CmsNewPageComponent(router, fb, translateService, pageService, appService, notificationsService, notifyService) {
        var _this = this;
        this.router = router;
        this.fb = fb;
        this.translateService = translateService;
        this.pageService = pageService;
        this.appService = appService;
        this.notificationsService = notificationsService;
        this.notifyService = notifyService;
        this.classes = 'popup-overlay';
        this.layoutsData = [];
        this.pageTemplates = [];
        this.selectedPageTemplate = null;
        this.customDataFieldChanged = false;
        this.layoutFieldChanged = false;
        this.submitDisabled = false;
        this.titleValidators = [
            Validators.required, Validators.maxLength(80)
        ];
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.subtitleValidationInterpolationParams = {
            field: this.translateService.translate('common_subtitle').toLowerCase(),
            maximum: '120'
        };
        this.keywordsValidationInterpolationParams = {
            field: this.translateService.translate('common_keywords').toLowerCase(),
            maximum: '120'
        };
        this.appService.getAllApps().subscribe(function (data) {
            if (data.apps && data.apps.length) {
                _this.apps = data.apps;
            }
        });
        this.pageService.getPageTemplates({ pageSize: 100000, extend: 'layouts' }).subscribe(function (data) {
            if (data.pageTemplates && data.pageTemplates.length) {
                _this.pageTemplates = data.pageTemplates;
            }
        });
    }
    CmsNewPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            name: ['', [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.pattern(NameValidationPattern.pattern)
                ]
            ],
            title: ['', this.titleValidators],
            subtitle: ['', [Validators.maxLength(120)]],
            app: ['', [Validators.required]],
            pageTemplate: [null],
            keywords: ['', [Validators.maxLength(120)]],
            customData: [{}, [validateJson]],
            active: [true, []]
        });
        this.pageService.getLayouts().subscribe(function (data) {
            _this.layoutsData = data.layouts;
            _this.form.addControl('layout', _this.buildLayouts());
            _this.form.controls.layout.setValidators([validateCheckboxRequired]);
            _this.form.get('layout').valueChanges.subscribe(function (value) {
                if (_this.selectedPageTemplate) {
                    _this.layoutFieldChanged = !isEqual(_this.getPageTemplateSelectedLayouts(_this.selectedPageTemplate.layouts), value);
                }
            });
        });
        this.form.get('customData').valueChanges.subscribe(function (value) {
            _this.customDataFieldChanged = !isEqual(_this.selectedPageTemplate.customData, value);
        });
    };
    CmsNewPageComponent.prototype.buildLayouts = function () {
        var _this = this;
        var arr = this.layoutsData.map(function (layout) {
            return _this.fb.control(false);
        });
        return this.fb.array(arr);
    };
    Object.defineProperty(CmsNewPageComponent.prototype, "layouts", {
        get: function () {
            return this.form.get('layout');
        },
        enumerable: true,
        configurable: true
    });
    ;
    CmsNewPageComponent.prototype.selectPageTemplate = function (e) {
        var _this = this;
        if (e) {
            this.pageService.getPageTemplate(e.id).subscribe(function (res) {
                _this.selectedPageTemplate = res.pageTemplate;
                var titleControl = _this.form.controls.title, pageTemplate = res.pageTemplate;
                var _loop_1 = function (key) {
                    if (_this.form.controls.hasOwnProperty(key)) {
                        var foundKey = FIELDS_OVERRIDE_WHITELIST.find(function (field) { return field.controlKey === key; });
                        if (foundKey) {
                            var control = _this.form.controls[foundKey.controlKey];
                            // if property in page template exist and current page control
                            // is not updated by user or empty then update control with page template value
                            if (pageTemplate.hasOwnProperty(foundKey['serverKey'] || foundKey.controlKey)
                                && (control.pristine || control.value === '')) {
                                if (key === 'layout') {
                                    // now select layouts extend from chosen page template
                                    control.patchValue(_this.getPageTemplateSelectedLayouts(pageTemplate.layouts));
                                }
                                else {
                                    control.patchValue(pageTemplate[key]);
                                }
                            }
                            else if (pageTemplate[key] === '' && control.pristine) {
                                // if current chosen page template property is empty and not yet
                                // updated by user then clear form value
                                control.reset();
                            }
                        }
                    }
                };
                for (var key in _this.form.controls) {
                    _loop_1(key);
                }
                // if page template is chosen, title is not required field,
                // only set max length validator
                titleControl.setValidators([_this.titleValidators[1]]);
                titleControl.updateValueAndValidity();
            });
        }
    };
    CmsNewPageComponent.prototype.getPageTemplateSelectedLayouts = function (pageTemplateLayouts) {
        var _this = this;
        var newState = [];
        // now select layouts extend from chosen page template
        this.form.controls.layout.value.forEach(function (val, index) {
            var currentLayout = _this.layoutsData[index];
            var found = filter(pageTemplateLayouts, function (el) {
                return el.id === currentLayout.id;
            });
            return found.length ? newState.push(true) : newState.push(false);
        });
        return newState;
    };
    CmsNewPageComponent.prototype.onSubmit = function (form) {
        var _this = this;
        var newPage = cloneDeep(form.value);
        var selectedLayouts = form.value.layout.map(function (val, index) {
            return {
                id: _this.layoutsData[index].id,
                selected: val
            };
        }).filter(function (val) {
            return val.selected;
        });
        var selectedPageTemplate = this.form.controls.pageTemplate.value;
        this.submitDisabled = true;
        // app id
        newPage.app = form.value.app.id;
        if (this.form.controls.pageTemplate.value) {
            // if they are same send null
            // otherwise send selected layouts
            if (isEqual(form.value.layout, this.getPageTemplateSelectedLayouts(selectedPageTemplate.layouts))) {
                selectedLayouts = null;
            }
            else {
                newPage.layouts = selectedLayouts;
            }
        }
        newPage.layouts = selectedLayouts;
        newPage.linkedPageTemplate = form.value.pageTemplate && form.value.pageTemplate.id;
        this.pageService.createPage(newPage).pipe(finalize(function () {
            _this.submitDisabled = true;
        })).subscribe(function (res) {
            _this.notifyService.notify({
                type: 'pages',
                data: res.pageTemplate
            });
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('pages_created')
            });
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsNewPageComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'merge' });
    };
    return CmsNewPageComponent;
}());
export { CmsNewPageComponent };
