import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsPagesService } from 'app/pages/shared/pages.service';
import { CmsAppsService } from '../../apps/shared/apps.service';
import { cloneDeep } from 'lodash';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { validateJson } from '../../shared/json-editor/json-validator.directive';
import { NameValidationPattern } from '../../shared/common.service';
import { finalize } from "rxjs/operators";
var CmsNewPageTemplateComponent = /** @class */ (function () {
    function CmsNewPageTemplateComponent(router, fb, translateService, pageService, appService, notifyService, notificationsService) {
        var _this = this;
        this.router = router;
        this.fb = fb;
        this.translateService = translateService;
        this.pageService = pageService;
        this.appService = appService;
        this.notifyService = notifyService;
        this.notificationsService = notificationsService;
        this.classes = 'popup-overlay';
        this.appTemplates = [];
        this.layoutsData = [];
        this.submitDisabled = false;
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.subtitleValidationInterpolationParams = {
            field: this.translateService.translate('common_subtitle').toLowerCase(),
            maximum: '120'
        };
        this.keywordsValidationInterpolationParams = {
            field: this.translateService.translate('common_keywords').toLowerCase(),
            maximum: '120'
        };
        this.appService.getAppsTemplates({ pageSize: 100000 }).subscribe(function (data) {
            if (data.appTemplates && data.appTemplates.length) {
                _this.appTemplates = data.appTemplates;
            }
        });
    }
    CmsNewPageTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            name: ['', [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.pattern(NameValidationPattern.pattern)
                ]
            ],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            subtitle: ['', [Validators.maxLength(120)]],
            appTemplate: ['', [Validators.required]],
            keywords: ['', [Validators.maxLength(120)]],
            customData: [{}, [validateJson]],
            active: [true, []]
        });
        this.pageService.getLayouts().subscribe(function (data) {
            _this.layoutsData = data.layouts;
            _this.form.addControl('layout', _this.buildLayouts());
        });
    };
    CmsNewPageTemplateComponent.prototype.buildLayouts = function () {
        var _this = this;
        var arr = this.layoutsData.map(function (layout) {
            return _this.fb.control(false);
        });
        return this.fb.array(arr);
    };
    Object.defineProperty(CmsNewPageTemplateComponent.prototype, "layouts", {
        get: function () {
            return this.form.get('layout');
        },
        enumerable: true,
        configurable: true
    });
    ;
    CmsNewPageTemplateComponent.prototype.onSubmit = function (form) {
        var _this = this;
        var newPage = cloneDeep(form.value);
        this.submitDisabled = true;
        newPage.appTemplate = form.value.appTemplate.id;
        newPage.layouts = form.value.layout.map(function (val, index) {
            return {
                id: _this.layoutsData[index].id,
                selected: val
            };
        }).filter(function (val) {
            return val.selected;
        });
        this.pageService.createPageTemplate(newPage).pipe(finalize(function () {
            _this.submitDisabled = true;
        })).subscribe(function (res) {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('pages_template_created')
            });
            _this.notifyService.notify({
                type: 'page-templates',
                data: res.pageTemplate
            });
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsNewPageTemplateComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'merge' });
    };
    return CmsNewPageTemplateComponent;
}());
export { CmsNewPageTemplateComponent };
