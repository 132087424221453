import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual, cloneDeep } from 'lodash';
import { CmsFormsService } from '../forms.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { validateJson } from '../../shared/json-editor/json-validator.directive';
import {debounceTime, finalize} from 'rxjs/operators';

@Component({
    selector: 'cms-edit-form',
    templateUrl: 'edit-form.component.html',
    providers: [CmsFormsService]
})

export class CmsEditFormComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    originalData: any;
    valueChanged: boolean = false;
    titleValidationInterpolationParams;
    submitDisabled = false;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService,
        private formService: CmsFormsService,
        private activeRoute: ActivatedRoute
    ) {
        this.form = this.fb.group({
            app: this.fb.group({
                title: [{ value: '', disabled: true }]
            }),
            appFormTemplate: this.fb.group({
                title: [{ value: '', disabled: true }]
            }),
            name: [{value: '', disabled: true}],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            data: [{}, [Validators.required, validateJson]],
            active: [true, []]
        });

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };

        this.activeRoute.params.subscribe((params: {formId: string}) => {
            this.formService.getForm({
                formId: params.formId,
                search: {extend: 'app,appFormTemplate'}
            }).subscribe(res => {
                this.originalData = cloneDeep(res.appForm);
                this.form.patchValue(res.appForm);
            });
        });
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(debounceTime(200))
            .subscribe((form: any) => {
                this.valueChanged = !(isEqual(form.title, this.originalData.title)
                    && isEqual(form.data, this.originalData.data)
                    && isEqual(form.active, this.originalData.active));
            });
    }

    onSubmit(form: FormGroup) {
        this.submitDisabled = true;
        this.formService.updateForm({
            formId: this.activeRoute.snapshot.params['formId'],
            title: form.value.title,
            data: form.value.data,
            active: form.value.active
        }).pipe(
            finalize(() => {
                this.submitDisabled = true;
            })
        ).subscribe(
            res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('forms_edit_form_success')
                });
                this.closePopup().then( () => {
                    this.notifyService.notify('formEdit');
                });
            },
            error => {
                this.notificationsService.showError(error);
            }
        );
    }

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'preserve'}
        );
    }
}
